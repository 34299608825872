@use "../base/breakpoints";

.build-caption-progress-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
}

.app-build__grid {
  gap: calc(var(--section-padding) / 2);
  margin-bottom: var(--section-padding);

  .jenkins-card {
    margin-bottom: 0; // Remove when .jenkins-card no longer has a default margin
  }

  @media (width < 1000px) {
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr 1fr;

    .jenkins-card__content .app-console-output-widget {
      max-height: 300px;
    }
  }

  @media (width >= 1000px) {
    display: grid;
    justify-content: stretch;
    grid-template-columns: 1fr 1fr;
    margin-top: calc(var(--section-padding) * -0.25);

    .jenkins-card {
      overflow: clip;
      margin-bottom: 0; // Remove when .jenkins-card no longer has a default margin

      &:first-of-type {
        grid-column: span 2;
      }

      &:last-of-type {
        grid-column: span 2;
      }
    }

    .jenkins-card__content .app-console-output-widget {
      height: 300px;
    }
  }

  @media (width >= 1300px) {
    grid-template-columns: 1fr 1fr 1fr;

    .jenkins-card {
      &:first-of-type {
        grid-column: span 2;
      }

      &:last-of-type {
        grid-column: span 3;
      }
    }
  }

  @media (width >= 1600px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;

    .jenkins-card {
      &:first-of-type {
        grid-column: span 3;
      }

      &:last-of-type {
        grid-column: span 4;
      }
    }
  }

  @media (width >= 1900px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

    .jenkins-card {
      &:first-of-type {
        grid-column: span 3;
      }

      &:last-of-type {
        grid-column: span 5;
      }
    }
  }
}

.app-build__clock {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
  scale: 0.75;

  &__hours,
  &__minutes {
    position: absolute;
    inset: 0;
    border-radius: 100%;

    &::after {
      content: "";
      position: absolute;
      bottom: 11px;
      left: 11px;
      width: 2px;
      background: currentColor;
      border-radius: 2px;
    }
  }

  &__hours {
    rotate: var(--hours);

    &::after {
      height: 6px;
    }
  }

  &__minutes {
    rotate: var(--minutes);

    &::after {
      height: 8px;
    }
  }

  &::after {
    position: absolute;
    content: "";
    inset: 0;
    border-radius: 100%;
    border: 2px solid currentColor;
  }
}

.app-console-output-widget {
  overflow-y: auto;
  margin: 0 -1rem -1rem;
  padding: 0 1rem 1rem;

  pre {
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
    line-height: 1.75;
    font-size: var(--font-size-sm);
  }
}
